import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ActionContext } from "modules/Action/context";
import { DraftActionActionType } from "modules/Action/reducers";
import React from "react";
import Button from "ui-kit/atoms/Button";

interface PreviewButtonProps {
  isPreviewable: boolean;
}

const PreviewButton = ({
  isPreviewable,
}: PreviewButtonProps): React.ReactElement | null => {
  const {
    dispatch,
    draftAction: { previewMode },
  } = React.useContext(ActionContext);

  const togglePreviewEnabled = () => {
    dispatch({
      type: DraftActionActionType.SET_DRAFT_ACTION,
      payload: { previewMode: !previewMode },
    });
  };

  console.log("isPreviewable", isPreviewable);

  return isPreviewable ? (
    <Button
      onClick={togglePreviewEnabled}
      variant="outlined"
      color="inherit"
      startIcon={previewMode ? <VisibilityOffIcon /> : <VisibilityIcon />}
      sx={{ pl: 3.5, mr: 2 }}
      size="small"
    >
      {previewMode ? "Hide" : "Show"} preview
    </Button>
  ) : null;
};

export default PreviewButton;
