import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { AccountContext } from "modules/Account/context";
import { ActionContext } from "modules/Action/context";
import PlaceholderService from "modules/Placeholder/services";
import {
  getAllPlaceholders,
  getCustomPlaceholders,
} from "modules/Placeholder/utils";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import PlaceholderTextEditor from "../PlaceholderTextEditor";
import PreviewPerson from "../PreviewPerson";
import { useLocation } from "react-router-dom";

const emptyDefaultValue = "";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    width: "100%",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    right: 0,
    position: "absolute",
    height: 32,
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.shorter,
    }),
  },
  actionsError: {
    margin: theme.spacing(-17, 2, 3),
  },
  actionsPreview: {
    paddingRight: "50%",
  },
  counter: {
    color: theme.app.palette.action.placeholder,
    marginRight: theme.spacing(1),
  },
  counterError: {
    color: theme.palette.error.main,
  },
  inputAdornment: {
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
  },
}));

interface PlaceholderEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  error?: string;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  endAdornmentSubmit?: boolean;
  disableCharacterCount?: boolean;
}

const PlaceholderEditor: React.FC<PlaceholderEditorProps> = ({
  value,
  onChange,
  placeholder,
  error,
  multiline = false,
  rows = 1,
  maxLength,
  endAdornmentSubmit,
  disableCharacterCount = false,
}) => {
  const classes = useStyles();
  // const location = useLocation();
  const [localValue, setLocalValue] = useState(value || emptyDefaultValue);

  // console.log("location", location);

  const {
    draftAction: { previewMode, personId, placeholders },
  } = useContext(ActionContext);
  const {
    account: { id: accountId },
  } = useContext(AccountContext);

  const handleChange = useCallback(
    (newValue: string) => {
      setLocalValue(newValue);
      onChange(newValue);
    },
    [onChange]
  );

  const fetchPlaceholderKeys = async () => {
    try {
      const response = await PlaceholderService.fetchPlaceholderKeys(accountId);
      return response.data;
    } catch (err) {
      throw new Error(String(err));
    }
  };

  const { data: placeholderData } = useQuery(
    ["placeholder_keys", accountId],
    () => fetchPlaceholderKeys(),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!multiline,
    }
  );

  const allPlaceholders = getAllPlaceholders(placeholderData?.results || []);
  const allCustomPlaceholders = getCustomPlaceholders(
    placeholderData?.results || []
  );

  const enabledPreview = useMemo(
    () => previewMode && !!(personId || placeholders),
    [previewMode, personId, placeholders]
  );

  console.log("enabledPreview", enabledPreview);

  useEffect(() => {
    setLocalValue(value || emptyDefaultValue);
  }, [value]);

  return (
    <div className={classes.root}>
      <PlaceholderTextEditor
        value={localValue}
        fieldValue={value}
        onChange={handleChange}
        placeholder={placeholder}
        error={error}
        multiline={multiline}
        rows={rows}
        maxLength={maxLength}
        enabledPreview={enabledPreview}
        endAdornmentSubmit={endAdornmentSubmit}
        allPlaceholders={allPlaceholders}
        allCustomPlaceholders={allCustomPlaceholders}
      />
      {!disableCharacterCount && (
        <div
          className={clsx({
            [classes.actions]: true,
            [classes.actionsPreview]: previewMode,
            [classes.actionsError]: !!error,
          })}
        >
          {maxLength && (
            <Typography
              variant="caption"
              className={clsx(classes.counter, {
                [classes.counterError]: maxLength - localValue?.length < 0,
              })}
            >
              Remaining characters: {maxLength - localValue?.length}/{maxLength}
            </Typography>
          )}
        </div>
      )}
      <div className={classes.actions}>
        <PreviewPerson />
      </div>
    </div>
  );
};

export default PlaceholderEditor;
